import './App.css';
import {getLoginUser} from './util/loginUtil';
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Login } from './comps/login';
import { Page } from './comps/page';

function App() {
	const [isInit, setInit] = useState(false);
	const [isLogin, setLogin] = useState(false);

	const init = async () => {
		const data = await getLoginUser();
		const {userData, isAdmin} = data || {};
		if (isAdmin) {
			setLogin(!!userData);
			console.log(userData);
		}
		setInit(true);
	};

	const loginChange = (userData) => {
		setLogin(!!userData);
	};

	useEffect(() => {
		init();
	}, []);

	return (
		<div className="App">
			{isInit && !isLogin && <Login callback={loginChange}/>}
			{isLogin && <Page loginChange={loginChange}/>}
		</div>
	);
}

export default App;
