import {getSessioinId, setSessionId, setUserData} from './local';
import * as $ from 'jquery';

export const post = (url, data) => {
    const sessionId = getSessioinId();
    return new Promise((resolve, reject) => {
        $.ajax({
            type: 'POST',
            url,
            contentType: 'application/json; charset=utf-8',
            processData: false,
            data: JSON.stringify({
                sessionId,
                ...data,
            }),
            dataType: 'json',
            success(res) {
                resolve(res);
            },
            error(err) {
                resolve();
            }
        });
    });
};


