import './index.css';
import React, { useEffect, useState, useRef } from 'react';
import { Button, Checkbox, Form, Input, Upload, message, InputNumber, Switch } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import {saveChangGuanInfo, getChangGuanInfo} from '../../util/requestUtil';


export const ChangGuanAdd = props => {
    const {modifyId} = props;
    const [form] = Form.useForm();
    const changGuanInfoRef = useRef();

    const uploadProps = {
        name: 'file',
        action: '/upload/file',
        maxCount: 1,
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                return 'aaaaaaa'
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const onFinish = async (values) => {
        values.status = values.status ? 0 : 1;
        const picUrl = values?.pic?.file?.response?.data?.file;
        values.pic = picUrl;
        for (const key in values) {
            if (values[key] === undefined) {
                delete values[key];
            }
        }
        const newData = {
            ...changGuanInfoRef.current,
            ...values,
        };
        const result = await saveChangGuanInfo(newData);
        if (result.error === 0) {
            props.saveSuccess?.();
        } else {
            message.error('参数错误');
        }
    };

    const onFinishFailed = () => {};

    const loadChangGuanInfo = async (id) => {
        const result = await getChangGuanInfo(id);
        changGuanInfoRef.current = result;
        form.setFieldsValue({
            ...result,
            status: result.status === 0,
        });
    };
    useEffect(() => {
        console.log('modifyId--',modifyId)
        if (modifyId) {
            loadChangGuanInfo(modifyId);
        } else {
            const values = form.getFieldsValue();
            for (const key in values) {
                values[key] = undefined;
            }
            changGuanInfoRef.current = undefined;
            form.setFieldsValue({
                ...values,
                status: true,
            });
        }
    }, [modifyId]);

    useEffect(() => {
    }, []);

    return <div>
        <Form
            form={form}
            name="changGuan"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={{status: true}}
        >
            <Form.Item
                label="场馆名"
                name="name"
                rules={[{ required: true, message: '请输入场馆名!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="场馆地址"
                name="address"
                rules={[{ required: true, message: '请输入场馆地址!' }]}
                >
                <Input />
            </Form.Item>

            <Form.Item
                label="场馆电话"
                name="phone"
                rules={[{ required: true, message: '请输入场馆电话!' }]}
                >
                <Input />
            </Form.Item>
            <Form.Item
                label="纬度(latitude)"
                name="lat"
                rules={[{ required: true, message: '请输入纬度(latitude)!' }]}
                >
                <InputNumber style={{width: '100%'}} stringMode />
            </Form.Item>
            <Form.Item
                label="经度(longitude)"
                name="lon"
                rules={[{ required: true, message: '请输入经度(longitude)!' }]}
                >
                <InputNumber style={{width: '100%'}} stringMode />
            </Form.Item>
            <Form.Item
                label="场馆图片"
                name="pic"
                rules={[{ required: true, message: '请选择场馆图片!' }]}
                >
                <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Form.Item>

            <Form.Item
                label="可否预约"
                name="status"
                >
                <Switch checkedChildren="预约" unCheckedChildren="停止" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">保存</Button>
            </Form.Item>
        </Form>
    </div>
};