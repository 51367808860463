import './index.css';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Tabs, Space, Switch, Table, Spin, Select, Modal, Col, Row, message } from "antd";
import {getUserList, modifyUserPermission, resetUserPassword} from '../../util/requestUtil';
import dayjs from 'dayjs';

const pageSize = 30;
export const UserPage = props => {
	const [userList, setUserList] = useState([]);
	const [showLoading, setLoading] = useState(false);
	const [pagination, setPagination] = useState();
	const [modifyUser, setModifyUser] = useState();
	const [changePasswordUser, setChangePasswordUser] = useState();
	const [form] = Form.useForm();


	const loadData = async (page = 1, pageSize, isInit) => {
		!isInit && setLoading(true);
		const res = await getUserList(page, pageSize);
		const {total, list, page: currentPage} = res?.data || {};
		setUserList(list || []);
		setPagination({
			current: currentPage,
			pageSize,
			total,
			onChange: loadData,
		});
		setLoading(false)
	};


	const modifyUserInfoFn = (userInfo) => {
		setModifyUser(userInfo);
		const permission = userInfo?.permission || 0;
		const changguan = permission & 1;
		const admin = permission >> 1 & 1;
		form.setFieldsValue({
			id: userInfo?.id,
            status: userInfo?.status === 0,
			changguan: changguan === 1,
			admin: admin === 1,
        });
	};

	const changePasswordFn = async () => {
		const res = await resetUserPassword(changePasswordUser?.id);
		setChangePasswordUser(undefined);
		if (res?.error === 0) {
			const newPassword = res.data;
			console.log(newPassword)
			Modal.success({
				title: '密码修改成功',
				content: `新密码为：${newPassword}`,
				okText: '确认',
			});
		} else {
			message.error('密码重置失败');
		}
	};

	const closeModify = () => {
		setModifyUser(undefined);
	};
	const modifyOk = async () => {
		const values = form.getFieldsValue(true);
		let permission = 0;
		if (values.changguan) {
			permission |= 1;
		}
		if (values.admin) {
			permission |= 1 << 1;
		}
		const data = {
			id: values.id,
			status: values.status ? 0 : 1,
			permission,
		};
		const res = await modifyUserPermission(data);
		if (res?.error === 0) {
			message.success('修改成功');
			loadData(pagination?.current, pagination?.pageSize);
		} else {
			message.success('修改失败');
		}
		console.log('values--', form.getFieldsValue(true))
		console.log('data-',data)
		setModifyUser(undefined);
	};

	const formatPhone = (phone) => {
		return phone ? phone.replace(/(.*?)(\d{4})(\d{4})$/, '$1****$3') : ''
	};


	useEffect(() => {
		loadData(1, pageSize, true);
	}, []);


	const columns = [
		{title: '用户名', dataIndex: 'name',},
		{title: '手机号', dataIndex: 'phone', render: (v, itemData) => {
			return <>{formatPhone(v)}</>;
		}},
		{title: '角色', dataIndex: 'permission', render: (v, itemData) => {
			const permission = [];
			if (v & 1 === 1) {
				permission.push('场馆侧');
			}
			if (v >> 1 & 1 === 1) {
				permission.push('管理员');
			}
			return <>{permission.join(', ')}</>;
		}},
		{title: '创建时间', dataIndex: 'created', render: (v, itemData) => {
			const d = dayjs(new Date(v * 1000));
			return <>{d.format('YYYY-MM-DD HH:mm:ss')}</>;
		}},
		{title: '状态', dataIndex: 'status', render: (v, itemData) => {
			const pmap = ['正常', '封禁'];
			return <>{pmap[v]}</>;
		},},
		{
			title: '操作',
			render: (v, itemData) => {
				return <Space>
					<Button onClick={() => modifyUserInfoFn(itemData)} type="primary">修改</Button>
					<Button onClick={() => setChangePasswordUser(itemData)} type="primary">重置密码</Button>
				</Space>
			},
		}
	];


	return <>
		<div>
			<Table pagination={pagination} rowKey={record=> 'row' + record.id} dataSource={userList} columns={columns} />
		</div>
		<Modal title='修改用户信息' okText='修改' open={!!modifyUser} onCancel={closeModify} onOk={modifyOk}>
			<Form
				form={form}
				name="changGuan"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				autoComplete="off"
				nameList={true}
			>
				<Row>
					<Col style={{textAlign: 'right', paddingBottom: '15px'}} span={8}>用户名:&nbsp;</Col>
					<Col span={16}>{modifyUser?.name}</Col>
				</Row>
				<Row>
					<Col style={{textAlign: 'right', paddingBottom: '15px'}} span={8}>电话:&nbsp;</Col>
					<Col span={16}>{formatPhone(modifyUser?.phone)}</Col>
				</Row>
				<Form.Item
					label="角色"
				>
					<Space.Compact>
						<Form.Item
							label="场馆侧"
							name='changguan'
							valuePropName="checked"
							style={{margin: '0 10px'}}
						>
							<Checkbox></Checkbox>
						</Form.Item>
						<Form.Item
							label="管理员"
							name='admin'
							valuePropName="checked"
							style={{margin: '0 10px'}}
						>
							<Checkbox></Checkbox>
						</Form.Item>
					</Space.Compact>
				</Form.Item>

				<Form.Item
					label="可否预约"
					name="status"
					>
					<Switch checkedChildren="正常" unCheckedChildren="封禁" />
				</Form.Item>

			</Form>
		</Modal>
		<Modal title='重置用户密码' cancelText='取消' okText='修改' open={!!changePasswordUser} onCancel={() => setChangePasswordUser(undefined)} onOk={changePasswordFn}>
			<p>确认重置 {changePasswordUser?.name} 的用户密码？</p>
		</Modal>
		<Spin spinning={showLoading} size='large' fullscreen />
	</>
};