import './index.css';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, message } from "antd";
import {login, loginOut} from '../../util/loginUtil';


export const Login = props => {
    const onFinish = async (values) => {
        const res = await login(values);
        const {userData, isAdmin} = res?.data || {};
        isAdmin && props?.callback?.(userData);
        if (userData && !isAdmin) {
            message.error('不是管理员');
            loginOut();
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return <div>
        <Form
            name="login"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={{phone: '13161996109', password: '13161996109'}}
        >
            <Form.Item
                label="手机号"
                name="phone"
                rules={[{ required: true, message: '请输入手机号!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="密码"
                name="password"
                rules={[{ required: true, message: '请输入密码!' }]}
                value="13161996109"
                >
                <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">登录</Button>
            </Form.Item>
        </Form>
    </div>
};