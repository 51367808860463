import './index.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Tabs, Space, Switch, Table, Spin, Select, Modal, message, Col, Row, InputNumber } from "antd";
import {getUserList, modifyUserPermission, resetUserPassword, getCommandBase, saveCommandBase} from '../../util/requestUtil';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const lrMin = 210;
const lrMax = 2070;
const udMin = 300;
const udMax = 4200;

export const Machine = props => {
	const [form] = Form.useForm();
	const [commandData, setCommandData] = useState({type: 1});


	const loadData = async () => {
		const result = await getCommandBase({type: 1});
		const resultData = result?.data;
		try {
			resultData.data = JSON.parse(resultData.data);
		} catch(e){}
		setCommandData(resultData || {type: 1});
		form.setFieldsValue(resultData?.data || {});
	};


	const onFinish = async values => {
		values.rotate = values.rotate || 0;
		values?.pointList?.forEach?.(item => {
			item.lr = item.lr || lrMin;
			item.ud = item.ud || udMin;
			item.speed = item.speed || 80;
		});
		const result = await saveCommandBase({
			id: commandData?.id,
			type: 1,
			data: values,
		});
		loadData();
		if (result.error === 0) {
			message.success('保存成功');
		} else {
			message.error('保存失败');
		}
	};

	useEffect(() => {
		loadData();
	}, []);




	return <>
		<Form
			form={form}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 16 }}
			style={{ maxWidth: 600 }}
			autoComplete="off"
			nameList={true}
			onFinish={onFinish}
		>
			<Form.Item
                label="旋转"
                name="rotate"
				extra='取值范围-30-30，0为不旋转，小于0为下旋，大于0为上旋'
                >
				<InputNumber step={1} defaultValue={0} min={-30} max={30}/>
                {/* <Slider min={-30} max={30} defaultValue={0} marks={rotateMarks} /> */}
            </Form.Item>
			<Form.List name="pointList">
			{(fields, { add, remove }) => (
				<>
				{fields.map((item, index) => {
					const { key, name, ...restField } = item;
					return <><Row key={key}>
						<Col span={4} style={{textAlign: 'right'}}><MinusCircleOutlined onClick={() => remove(name)} />&nbsp;落点{index + 1}: </Col>
						<Col span={12}>
							<div>
								<Form.Item {...restField} extra={`取值范围${lrMin}-${lrMax}`} name={[name, 'lr']} label='水平'>
									<InputNumber step={10} defaultValue={lrMin} min={lrMin} max={lrMax}/>
								</Form.Item>
							</div>
							<div>
								<Form.Item {...restField} extra={`取值范围${udMin}-${udMax}`} name={[name, 'ud']} label='垂直'>
									{/* <Slider min={udMin} max={udMax} /> */}
									<InputNumber step={10} defaultValue={udMin} min={udMin} max={udMax}/>
								</Form.Item>
							</div>
							<div>
								<Form.Item
									label="速度"
									name={[name, "speed"]}
									extra='取值范围80-180'
									>
									<InputNumber step={10} defaultValue={80} min={80} max={180}/>
									{/* <Slider min={80} max={180} /> */}
								</Form.Item>
							</div>
						</Col>
						{/* <Col span={9}>
							<Form.Item {...restField} name={[name, 'ud']} label='垂直'>
								<Slider min={udMin} max={udMax} />
							</Form.Item>
						</Col> */}
					</Row>
					</>
				})}
				
				<Row>
					<Col offset={4} span={20}>
						<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>添加落点</Button>
					</Col>
				</Row>
				</>
			)}
			</Form.List>


			<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Space style={{paddingTop: '20px'}}>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Space>
            </Form.Item>
		</Form>
	</>
};