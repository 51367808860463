import './index.css';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Tabs, Space, Switch, Table, Spin, Select, Modal, Col, Row, message, Image } from "antd";
import {getOrderList, modifyUserPermission, resetUserPassword, getUserByIdList} from '../../util/requestUtil';
import dayjs from 'dayjs';
import {formatMinuteStr} from '../../util/util';
import {DrawNtrp} from '../../canvas_util/draw-ntrp';



const userCache = {};

const pageSize = 30;
export const OrderPage = props => {
	const [orderList, setOrderList] = useState([]);
	const [showLoading, setLoading] = useState(false);
	const [pagination, setPagination] = useState();
	const [modifyUser, setModifyUser] = useState();
	const [form] = Form.useForm();


	const loadData = async (page = 1, pageSize, isInit) => {
		!isInit && setLoading(true);
		const res = await getOrderList(page, pageSize);
		console.log('res---', res);
		const {total, list, page: currentPage} = res?.data || {};

		const uidList = [];
		list?.forEach?.(item => {
			const {uid} = item;
			if (!userCache[uid]) {
				uidList.push(uid);
			}
			try {
				item.result = JSON.parse(item.result);
			} catch (e) {
			}
		});
		if (uidList.length) {
			const userData = await getUserByIdList(uidList);
			userData?.data?.forEach?.(data => {
				userCache[data.id] = data;
			});
		}

		setOrderList(list || []);
		setPagination({
			current: currentPage,
			pageSize,
			total,
			onChange: loadData,
		});
		setLoading(false)
	};




	const closeModify = () => {
		setModifyUser(undefined);
	};
	const modifyOk = async () => {
		const values = form.getFieldsValue(true);
		let permission = 0;
		if (values.changguan) {
			permission |= 1;
		}
		if (values.admin) {
			permission |= 1 << 1;
		}
		const data = {
			id: values.id,
			status: values.status ? 0 : 1,
			permission,
		};
		const res = await modifyUserPermission(data);
		if (res?.error === 0) {
			message.success('修改成功');
			loadData(pagination?.current, pagination?.pageSize);
		} else {
			message.success('修改失败');
		}
		console.log('values--', form.getFieldsValue(true))
		console.log('data-',data)
		setModifyUser(undefined);
	};

	const formatPhone = (phone) => {
		return phone ? phone.replace(/(.*?)(\d{4})(\d{4})$/, '$1****$3') : ''
	};


	useEffect(() => {
		loadData(1, pageSize, true);
	}, []);


	const columns = [
		{title: '用户名', dataIndex: 'uid', render: (uid) => {
			return <>{userCache[uid].name}</>
		}},
		{title: 'NTRP', dataIndex: 'result', render: (result, itemData) => {
			if (itemData.status !== 3 || !result?.overallScore) {
				return <>-</>;
			}
			const ntrpObj = new DrawNtrp();
			ntrpObj.draw(result?.overallScore);
			const imageUrl = ntrpObj.toDataURL();
			return <><Image src={imageUrl} width={200}/></>;
		}},
		{title: '预约时间', dataIndex: 'dayTime', render: (dayTime, itemData) => {
			const d = dayjs(new Date(dayTime * 1000));
			const {start, end} = itemData;
			return <>{d.format('YYYY-MM-DD')} {formatMinuteStr(start) + '-' + formatMinuteStr(end)}</>;
		}},
		{title: '完成时间', dataIndex: 'overtime', render: (overtime, itemData) => {
			if (!overtime) {
				return <>-</>;
			}
			const d = dayjs(new Date(overtime * 1000));
			return <>{d.format('YYYY-MM-DD')}</>;
		}},
		{title: '状态', dataIndex: 'status', render: (v, itemData) => {
			const pmap = ['未付款', '已预约', '评估中', '已完成', '已逾期', '已取消'];
			return <>{pmap[v]}</>;
		},},
		{
			title: '操作',
			render: (v, itemData) => {
				return <Space>
					{itemData.status === 1 && <Button type="primary">取消订单</Button>}
					{itemData.status === 3 && <Button type="primary">查看数据</Button>}
				</Space>
			},
		}
	];


	return <>
		<div>
			<Table pagination={pagination} rowKey={record=> 'row' + record.id} dataSource={orderList} columns={columns} />
		</div>
		<Modal title='修改用户信息' okText='修改' open={!!modifyUser} onCancel={closeModify} onOk={modifyOk}>
			<Form
				form={form}
				name="changGuan"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				autoComplete="off"
				nameList={true}
			>
				<Row>
					<Col style={{textAlign: 'right', paddingBottom: '15px'}} span={8}>用户名:&nbsp;</Col>
					<Col span={16}>{modifyUser?.name}</Col>
				</Row>
				<Row>
					<Col style={{textAlign: 'right', paddingBottom: '15px'}} span={8}>电话:&nbsp;</Col>
					<Col span={16}>{formatPhone(modifyUser?.phone)}</Col>
				</Row>
				<Form.Item
					label="角色"
				>
					<Space.Compact>
						<Form.Item
							label="场馆侧"
							name='changguan'
							valuePropName="checked"
							style={{margin: '0 10px'}}
						>
							<Checkbox></Checkbox>
						</Form.Item>
						<Form.Item
							label="管理员"
							name='admin'
							valuePropName="checked"
							style={{margin: '0 10px'}}
						>
							<Checkbox></Checkbox>
						</Form.Item>
					</Space.Compact>
				</Form.Item>

				<Form.Item
					label="可否预约"
					name="status"
					>
					<Switch checkedChildren="正常" unCheckedChildren="封禁" />
				</Form.Item>

			</Form>
		</Modal>
		<Spin spinning={showLoading} size='large' fullscreen />
	</>
};