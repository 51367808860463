import './index.css';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Table,Switch, Form, Input, Select, Tabs, Space, InputNumber, Slider, Row, Col, Modal, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {getCommand, saveCommand, getConfig} from '../../util/requestUtil';

let cacheTestTypeList;
let lineTypeListBase;

export const XiaoJieForm = props => {
	const {formData} = props;
	const [form] = Form.useForm();
	const lrMin = 210;
	const lrMax = 2070;
	const udMin = 300;
	const udMax = 4200;
	const [isMachine, setMachind] = useState(true);
	const [testTypeList, setTestTypeList] = useState([]);
	const [lineTypeList, setLineTypeList] = useState([]);

	window.form = form;


	// NTRP评分，运动概况，底线-落点，底线-力量，底线-姿态，底线-切削，底线-小球，发球-落点，发球-力量，发球-姿态，网前-截击，移动能力，
	// NTRP评分，运动时长，平均球速，移动距离，击球次数，消耗热量，方向控制，第一落点，深度，球速，动力链，第二落点，
	// 挥拍完整度，击球点，第三落点，优质球，移动能力，站位， 正反手，发球

	const loadCommandData = async () => {
		if (cacheTestTypeList) {
			setTestTypeList(cacheTestTypeList);
			setLineTypeList(lineTypeListBase);
		}
		const {testTypeList, lineTypeList} = await getConfig();
		testTypeList.forEach(item => {
			item.label = item.text;
		});
		lineTypeList.forEach(item => {
			item.label = item.text;
		});
		lineTypeListBase = lineTypeList;
		cacheTestTypeList = testTypeList;
		setTestTypeList(testTypeList);
		setLineTypeList(lineTypeList);
	};


	const cancelEdit = () => {
		clearForm();
		props?.cancelEdit?.();
	};
	const onFinishFailed = () => {};
	const onFinish = async () => {
		const values = form.getFieldsValue(true);
		values.frequency *= 10;
		const pointList = values.pointList || [];
		pointList?.forEach?.((item, index) => {
			if (!item) {
				item = {};
				pointList[index] = item;
			}
			item.lr = item.lr === undefined ? lrMin : item.lr;
			item.ud = item.ud === undefined ? udMin : item.ud;
			item.speed = item.speed === undefined ? 80 : item.speed;
			item.test_type = item.test_type === undefined ? testTypeList?.[0]?.value : item.test_type;
		});
		props?.onResult?.(values);
	};

	useEffect(() => {
		clearForm();
		loadCommandData();
	}, []);

	useEffect(() => {;
		formData.frequency = formData.frequency || 20;
		formData.frequency /= 10;
		formData && form.setFieldsValue({
			...formData,
		});
		const machine = formData.machine;
		machine !== undefined && setMachind(machine);
	}, [formData]);



	const clearForm = () => {
		setMachind(true);
		form.setFieldsValue({
			id: null,
			name: '',
			pointList: [],
			count: 0,
			test_count: 0,
			frequency: 2,
			rotate: 0,
			speed: 80,
			loop: false,
			machine: true,
			test_content: '',
			random: false,
			sectionStart: '',
			sectionPause: '',
			sectionContinue: '',
			sectionSelect: '',
			serveOverMessage: '',
		});
	};


	const frequencyFormatter = (value) => {
		return `${value / 10}秒`;
	};

	const formValuesChange = (changedValues, allValues) => {
		if ('machine' in changedValues) {
			setMachind(changedValues.machine)
		}
	};

	const messageExtra = '通配符规则：{no}表示当前球顺序，{group}表示当前分组顺序，{type}表示当前球测试类型';

	return <div>
		
		<Form
            form={form}
            name="changdi"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            style={{ maxWidth: 1000 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
			onValuesChange={formValuesChange}
        >
            <Form.Item
                label="测试名称"
                name="name"
                rules={[{ required: true, message: '请输入阶段名称!' }]}
				size='small'
            >
                <Input />
            </Form.Item>
			<Form.Item
                label="测试内容"
                name="test_content"
                >
                <Input />
            </Form.Item>
            <Form.Item
                label="小节开始提示"
                name="sectionStart"
				extra={messageExtra}
                >
				<Input placeholder="输入语音提示文字"/>
            </Form.Item>
			<Form.Item
                label="小节暂停提示"
                name="sectionPause"
				extra={messageExtra}
                >
				<Input placeholder="输入语音提示文字"/>
            </Form.Item>
			<Form.Item
                label="暂停后继续提示"
                name="sectionContinue"
				extra={messageExtra}
                >
				<Input placeholder="输入语音提示文字"/>
            </Form.Item>
			<Form.Item
                label="选球（组）开始提示"
                name="sectionSelect"
				extra={messageExtra}
                >
				<Input placeholder="输入语音提示文字"/>
            </Form.Item>
			<Form.Item
                label="发球结束提示"
                name="serveOverMessage"
				extra={messageExtra}
                >
				<Input placeholder="输入语音提示文字"/>
            </Form.Item>

			<Form.Item
                label="发球总数(含热身球)"
                name="count"
				rules={[{ required: true, message: '请输入发球总数!' }]}
                >
                <InputNumber />
            </Form.Item>

			<Form.Item
                label="热身球数"
                name="test_count"
                >
                <InputNumber />
            </Form.Item>

			<Form.Item
                label="随机发球"
                name="random"
                >
                <Switch />
            </Form.Item>

			<Form.Item
                label="发球机命令"
                name="machine"
				tooltip="勾选设置发球机命令"
                >
                <Switch />
            </Form.Item>

			{isMachine && <>
			<Form.Item
                label="分组球数"
                name="group_count"
				extra='连续的N个球分为一组，传0为不分组，热身球为单独一组'
                >
				<InputNumber step={1} defaultValue={0}/>
                {/* <Slider tooltip={{ formatter: frequencyFormatter }} min={18} max={88} /> */}
            </Form.Item>
			<Form.Item
                label="发球频率"
                name="frequency"
				extra='取值范围2-8'
                >
				<InputNumber step={1} defaultValue={2} min={2} max={8} addonAfter={<>秒</>}/>
                {/* <Slider tooltip={{ formatter: frequencyFormatter }} min={18} max={88} /> */}
            </Form.Item>

			<Form.Item
                label="旋转"
                name="rotate"
				extra='取值范围-30-30，0为不旋转，小于0为下旋，大于0为上旋'
                >
				<InputNumber step={1} defaultValue={0} min={-30} max={30}/>
                {/* <Slider min={-30} max={30} defaultValue={0} marks={rotateMarks} /> */}
            </Form.Item>


			<Form.Item
                label="循环发球"
                name="loop"
				tooltip="已设置的球轮询发送，直到达到设置的最大发球数"
                >
                <Switch />
            </Form.Item>

			<Form.List name="pointList">
			{(fields, { add, remove }) => (
				<>
				{fields.map((item, index) => {
					const { key, name, ...restField } = item;
					return <><Row key={key}>
						<Col span={4} style={{textAlign: 'right'}}><MinusCircleOutlined onClick={() => remove(name)} />&nbsp;落点{index + 1}: </Col>
						<Col span={12}>
							<div>
								<Form.Item {...restField} extra={`取值范围${lrMin}-${lrMax}`} name={[name, 'lr']} label='水平'>
									{/* <Slider min={lrMin} max={lrMax} /> */}
									<InputNumber step={10} defaultValue={lrMin} min={lrMin} max={lrMax}/>
								</Form.Item>
							</div>
							<div>
								<Form.Item {...restField} extra={`取值范围${udMin}-${udMax}`} name={[name, 'ud']} label='垂直'>
									{/* <Slider min={udMin} max={udMax} /> */}
									<InputNumber step={10} defaultValue={udMin} min={udMin} max={udMax}/>
								</Form.Item>
							</div>
							<div>
								<Form.Item label="连续球数" name={[name, "count"]}>
									<InputNumber defaultValue={1}/>
								</Form.Item>
							</div>
							<div>
								<Form.Item
									label="速度"
									name={[name, "speed"]}
									extra='取值范围80-180'
									>
									<InputNumber step={10} defaultValue={80} min={80} max={180}/>
									{/* <Slider min={80} max={180} /> */}
								</Form.Item>
							</div>
							<div>
								<Form.Item
								name={[name, 'test_type']}
								label='测试类型'
								>
									<Select
										// mode="multiple"
										// allowClear
										options={testTypeList}
										defaultValue={1}
									/>
								</Form.Item>
							</div>
							<Form.Item name={[name, 'line_type']} label='线路'>
								<Select
									// mode="multiple"
									// allowClear
									options={lineTypeList}
									defaultValue={0}
								/>
							</Form.Item>
						</Col>
						{/* <Col span={9}>
							<Form.Item {...restField} name={[name, 'ud']} label='垂直'>
								<Slider min={udMin} max={udMax} />
							</Form.Item>
						</Col> */}
					</Row>
					</>
				})}
				
				<Row>
					<Col offset={4} span={20}><Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>添加落点</Button></Col>
				</Row>
				</>
			)}
			</Form.List>
			</>}

			{!isMachine && <>
				<Form.Item
					label="循环发球"
					name="loop"
					tooltip="已设置的球轮询发送，直到达到设置的最大发球数"
					>
					<Switch/>
				</Form.Item>
				<Form.List name="serveList">
				{(fields, { add, remove }) => <>
					{fields.map((item, index) => {
						const { key, name, ...restField } = item;
						return <>
						<Row>
							<Col span={4} style={{textAlign: 'right', fontWeight: 'bold', fontSize: '16px'}}>{`第${index + 1}球：`}</Col>
							<Col><MinusCircleOutlined onClick={() => remove(name)} /></Col>
						</Row>
						<Form.Item
							name={[name, 'serve1Start']}
							label='一发开始提示'
							extra='通配符规则：{no}表示当前球顺序，{group}表示当前分组顺序，{type}表示当前球测试类型'
						>
							<Input placeholder="输入语音提示文字"/>
						</Form.Item>
						<Form.Item
							name={[name, 'serve2Start']}
							label='二发开始提示'
							extra=''
						>
							<Input placeholder="输入语音提示文字"/>
						</Form.Item>
						<Form.Item
							name={[name, 'serve1Restart']}
							label='一发重打提示'
							extra=''
						>
							<Input placeholder="输入语音提示文字"/>
						</Form.Item>
						<Form.Item
							name={[name, 'serve2Restart']}
							label='二发重打提示'
							extra=''
						>
							<Input placeholder="输入语音提示文字"/>
						</Form.Item>
						<Form.Item label="连续球数" name={[name, "count"]}>
							<InputNumber defaultValue={1}/>
						</Form.Item>
						<Form.Item name={[name, 'test_type']} label='测试类型'>
							<Select
								// mode="multiple"
								// allowClear
								options={testTypeList}
							/>
						</Form.Item>
						<Form.Item name={[name, 'line_type']} label='线路'>
							<Select
								// mode="multiple"
								// allowClear
								options={lineTypeList}
								defaultValue={0}
							/>
						</Form.Item>
						</>
					})}
					<Row>
						<Col offset={4} span={20}><Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>添加发球</Button></Col>
					</Row>
				</>}
				</Form.List>
			</>}


            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Space style={{paddingTop: '20px'}}>
                    <Button type="primary" htmlType="submit">{typeof formData?.itemIndex === 'number' && formData?.itemIndex >= 0 ? '修改' : '添加'}</Button>
                    <Button onClick={cancelEdit}>取消</Button>
                </Space>
            </Form.Item>
        </Form>
	</div>
};