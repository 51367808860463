
// 雷达图
export const CanvasRadar = function(canvas, config) {
    let {
        width,
        height,
        center,
        maxRadius,
        ringColors,
        dataStyle = {
            alpha: 0.4,
            color: '#52C792',
        },
        scale = 1,
    } = config;
    maxRadius = maxRadius * scale;
    const ctx = canvas.getContext('2d');
    const centerX = center[0] * scale;
    const centerY = center[1] * scale;

    function angleToRadian(angle) {
        angle = angle % 360 - 90;
        const radian = 2 * Math.PI * angle / 360;
        return radian;
    }

    function getAnglePosition(angle, radius) {
        let x = centerX + radius * Math.cos(angleToRadian(angle));
        let y = centerY + radius * Math.sin(angleToRadian(angle));
        return {x, y};
    }

    function drawText(text, angle, radius) {
        if (!text) {
            return;
        }
        const angleOffset = 20;
        angle = (angle + 360) % 360;
        const {x, y} = getAnglePosition(angle, radius);
        const fontSize = 12;
        ctx.font = `${fontSize * scale}px Arial`;
        ctx.fillStyle = '#333';
        const halfTextWidth = text.length * fontSize * scale / 2;
        let left = x - halfTextWidth;
        let top = y;
        if (angle > angleOffset && angle < 180 - angleOffset) {
            left += halfTextWidth;
        } else if (angle > 180 + angleOffset && angle < 360 - angleOffset) {
            left -= halfTextWidth;
        }
        if (angle > 180 - angleOffset && angle < 180 + angleOffset) {
            top += 5 * scale;
        }
        ctx.fillText(text, left, top);
    }

    function drawRound(radius, color, alpha) {
        const roundAngle = 4;
        const halfRoundAngle = roundAngle / 2;
        const count = ringColors.length;
        const angleItem = 360 / count;
        ctx.globalAlpha = alpha;
        ctx.beginPath();
        for (let i = 0; i < count; i++) {
            const angle = i * angleItem;
            const lineEndAngle = (i + 1) * angleItem;
            const arcStartAngle = angle - halfRoundAngle;
            const arcEndAngle = angle + halfRoundAngle;
            const lineEndPos = getAnglePosition(lineEndAngle - halfRoundAngle, radius);
            ctx.arc(centerX, centerY, radius, angleToRadian(arcStartAngle), angleToRadian(arcEndAngle));
            ctx.lineTo(lineEndPos.x, lineEndPos.y);
        }

        ctx.lineWidth = 1 * scale;
        ctx.closePath();
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.globalAlpha = 1;
    }

    function drawBackground(dataList) {
        const count = dataList.length;
        const angleItem = 360 / count;
        const radiusSpace = maxRadius / count;
        ringColors.forEach((item, index) => {
            const {color, text, alpha = 1} = item;
            const radius = maxRadius - index * radiusSpace;
            drawRound(radius, color, alpha);
        });
    }

    function drawData(dataList) {
        const count = dataList.length;
        const angleItem = 360 / count;
        ctx.beginPath();
        ctx.globalAlpha = dataStyle.alpha;
        let isMoveTo = false;
        dataList.forEach((item, index) => {
            const {name, value, max} = item;
            if (typeof value !== 'number') {
                return;
            }
            const radius = maxRadius * value / max;
            const angle = index * angleItem;
            const {x, y} = getAnglePosition(angle, radius);
            if (!isMoveTo) {
                isMoveTo = true;
                ctx.moveTo(x, y);
            } else {
                ctx.lineTo(x, y);
            }
        });
        ctx.lineWidth = 1 * scale;
        ctx.fillStyle = dataStyle.color;
        ctx.closePath();
        ctx.fill();
        ctx.globalAlpha = 1;

        dataList.forEach((item, index) => {
            const {name, value, max} = item;
            drawText(name, angleItem * index, maxRadius + 10 * scale)
        });
    }


    this.setData = (dataList) => {
        if (dataList) {
            drawBackground(dataList);
            drawData(dataList);
        }
    };
};

