import {getSessioinId, setSessionId, setUserData} from './local';
import {post} from './request';

export const getLoginUser = async () => {
    const sessionId = getSessioinId();
    return new Promise(async (resolve, reject) => {
        if (sessionId) {
            const res = await post('/login/check', {sessionId});
            const {userData, isAdmin} = res?.data || {};
            if (isAdmin) {
                setUserData(userData);
                resolve(res?.data);
                return;
            }
            resolve(undefined);
        } else {
            resolve(undefined);
        }
    });
};

export const login = async (params) => {
    return new Promise(async (resolve, reject) => {
        const res = await post('/login/admin', params);
        const {sessionId, userData} = res?.data || {};
        if (sessionId) {
            setSessionId(sessionId);
            setUserData(userData);
        }
        resolve(res);
    });
};

export const loginOut = () => {
    setSessionId(undefined);
    setUserData(undefined);
};


