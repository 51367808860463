const localKey = 'dkfjieuurfkajfhi937483h';
const storeData = {
    userData: undefined,
};

let localJsonData;
try {
    const localStr = localStorage.getItem(localKey);
    localJsonData = JSON.parse(localStr);
} catch (e) {
}

const localData = {...localJsonData};

export const getSessioinId = () => {
    return localData.sessionId;
};


export const saveLocalData = () => {
    try {
        const localStr = JSON.stringify(localData);
        localStorage.setItem(localKey, localStr);
    } catch (e) {
    }
};

export const setSessionId = (sessionId) => {
    localData.sessionId = sessionId;
    saveLocalData();
};

export const setUserData = (userData) => {
    storeData.userData = userData;
};

