
import {CustomCanvas, createOffscreenCanvas} from '../utils/canvas';
import {CanvasRadar} from '../utils/canvas-radar';


// 画ntrp数据
export const DrawNtrp = function (canvas) {
    const scale = 3;
	const width = 324;
	const height = 135;
    canvas = canvas || createOffscreenCanvas({
		type: '2d',
		width: width * scale,
		height: height * scale,
	});

    const customCanvas = new CustomCanvas(canvas, {scale});
    const canvasRadar = new CanvasRadar(canvas, {
        scale,
        center: [250, height / 2],
        maxRadius: height / 2 - 30,
        ringColors: [
            {color: '#52C993'},
            {color: '#52C792', alpha: 0.4},
            {color: '#52C792', alpha: 0.4},
            {color: '#52C792', alpha: 0.4},
            {color: '#52C792', alpha: 0.4},
            {color: '#52C792', alpha: 0.4},
        ],
        dataStyle: {
            alpha: 0.4,
            color: '#52C792',
        },
    });

    this.draw = async (ntrpData) => {
        const {ntrp, forehand, backhand, serve, netFront, tactics, move} = ntrpData;


        const radarList = [
			{name: '正手', value: forehand, max: 5},
			{name: '反手', value: backhand, max: 5},
			{name: '发球', value: serve, max: 5},
			{name: '战术', value: tactics, max: 5},
			{name: '网前', value: netFront, max: 5},
            {name: '移动', value: move, max: 5},
		];
        canvasRadar.setData(radarList); // 雷达图

        customCanvas.fillRoundReactForPos(0, 2, 166, 36, 10, '#F8F8F8');
        customCanvas.fillRect(0, 20, 166, 18, '#F8F8F8');
        customCanvas.fillTextByHorizontalCenter('NTRP：' + ntrp.toFixed(1), '#111111', 16, 79, 24, true);
        customCanvas.fillRect(0, 41, 166, 127 - 33, '#F8F8F8');
        const leftTextLeft = 12;
        const rightTestLeft = 105;
        let textRes = customCanvas.fillText('正手：', '#333333', 10, leftTextLeft, 68);
        customCanvas.fillText(forehand.toFixed(1), '#333333', 12, leftTextLeft + textRes.width, 68, true);
        textRes = customCanvas.fillText('反手：', '#333333', 10, rightTestLeft, 68);
        customCanvas.fillText(backhand.toFixed(1), '#333333', 12, rightTestLeft + textRes.width, 68, true);

        textRes = customCanvas.fillText('发球：', '#333333', 10, leftTextLeft, 90);
        customCanvas.fillText(serve.toFixed(1), '#333333', 12, leftTextLeft + textRes.width, 90, true);
        textRes = customCanvas.fillText('网前：', '#333333', 10, rightTestLeft, 90);
        customCanvas.fillText(netFront.toFixed(1), '#333333', 12, rightTestLeft + textRes.width, 90, true);

        textRes = customCanvas.fillText('移动：', '#333333', 10, leftTextLeft, 114);
        customCanvas.fillText(move.toFixed(1), '#333333', 12, leftTextLeft + textRes.width, 114, true);
        textRes = customCanvas.fillText('战术：', '#333333', 10, rightTestLeft, 114);
        customCanvas.fillText(tactics.toFixed(1), '#333333', 12, rightTestLeft + textRes.width, 114, true);
    };

    this.toDataURL = customCanvas.toDataURL;
    this.toTempFilePath = customCanvas.toTempFilePath;
    this.getSize = () => ({
        width,
        height,
        scale,
    });

    this.getCanvas = () => canvas;
};
