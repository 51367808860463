import './index.css';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, message, Space } from "antd";
import {loadChangGuanList, getChangGuanInfo} from '../../util/requestUtil';
import {ChangGuanAdd} from '../changguan_add';
import { ChangGuanList } from '../changguan_list';
import {EditChangDi} from '../edit_changdi';


export const ChangGuan = props => {
    const [changGuanList, setChangGuanList] = useState([]);
    const [pageType, setPageType] = useState('list');
    const [modifyId, setModifyId] = useState();
    const [changGuanInfo, setChangGuanInfo] = useState();


    const getChangGuanData = async () => {
        const list = await loadChangGuanList();
        console.log('chang guan list=', list)
        setChangGuanList(list);
    };

    const modifyChangGuan = async (id) => {
        setPageType('edit');
        setModifyId(id);
    };

    const editChangDi = (info) => {
        setChangGuanInfo(info);
        setPageType('changdi');
    };

    const saveSuccess = () => {
        setPageType('list');
        setModifyId(undefined);
        message.success('保存成功');
        getChangGuanData();
    };

    const addChangGuan = () => {
        setModifyId(undefined);
        setPageType('edit');
    };


    useEffect(() => {
        getChangGuanData();
    }, []);

    return <div>
        <Space>
            <Button type="primary" onClick={() => setPageType('list')}>场馆列表</Button>
            <Button type="primary" onClick={addChangGuan}>添加场馆信息</Button>
        </Space>
        <div>
            {pageType === 'list' && <ChangGuanList editChangDi={editChangDi} reloadData={getChangGuanData} modify={modifyChangGuan} list={changGuanList}/>}
            {pageType === 'edit' && <ChangGuanAdd modifyId={modifyId} saveSuccess={saveSuccess} />}
            {pageType === 'changdi' && <EditChangDi info={changGuanInfo}/>}
        </div>

    </div>
};