import './index.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Upload, message, InputNumber, Table, Image, Modal, Space } from "antd";
import {saveChangGuanInfo, deleteChangGuan} from '../../util/requestUtil';


export const ChangGuanList = props => {
    const {list} = props;
    const [selectRows, setSelectRows] = useState([]);
    const [showDelModel, setShowDelModel] = useState(false);

    const modify = () => {
        if (selectRows?.length === 1) {
            props.modify?.(selectRows[0].id);
        } else {
            message.error('只能选择一项');
        }
    };

    const editChangDi = () => {
        if (selectRows?.length === 1) {
            props.editChangDi?.(selectRows[0]);
        } else {
            message.error('只能选择一项');
        }
    };

    const deleteChangGuanData = () => {
        if (selectRows?.length) {
            setShowDelModel(true);
        }
    };

    const confirmDelete = async () => {
        console.log('confirmDelete')
        setShowDelModel(false);
        const idList = [];
        selectRows?.forEach(item => {
            idList.push(item.id);
        });
        const result = await deleteChangGuan(idList);
        if (result.error === 0) {
            message.success('删除成功');
            props.reloadData?.();
        } else {
            message.error('删除失败');
        }
    };
    const cancelDelete = () => {
        setShowDelModel(false);
    };


    const rowSelection = {
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectRows(selectedRows);
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }
    };

    const columns = [
        {title: '场馆名',dataIndex: 'name',},
        {title: '地址',dataIndex: 'address',},
        {title: '电话',dataIndex: 'phone',},
        {
            title: '经纬度',dataIndex: 'lat',key: 'lat',
            render: (lat, record) => <>{lat},{record.lon}</>
        },
        {
            title: '图片',dataIndex: 'pic',
            render: (url) => <Image src={url} width={200}/>,
        },
        {
            title: '状态',dataIndex: 'status',
            render: (status) => <>{status === 0 ? '可预约' : '不可预约'}</>,
        }
    ];


    return <>
        <div>
            <Table rowKey={record=> 'row' + record.id} rowSelection={rowSelection} dataSource={list} columns={columns} />
            <Space>
                <Button type="primary" onClick={modify}>修改</Button>
                <Button type="primary" onClick={editChangDi}>编辑场地信息</Button>
                <Button onClick={deleteChangGuanData}>删除</Button>
            </Space>
        </div>
        <Modal title="删除确认" open={showDelModel} onOk={confirmDelete} onCancel={cancelDelete}>
            {
                selectRows?.map(item => {
                    return <p key={'del' + item.id}>确认删除场馆：{item.name}</p>
                })
            }
        </Modal>
    </>
};