import './index.css';
import React, { useEffect, useState } from 'react';
import { Button, Select, Checkbox, Form, Input, Upload, message, Table, Switch, Space, DatePicker, Col, Row, Modal } from "antd";
import {loadChangDiList, saveChangDiInfo, deleteChangDiInfo, getChangDiAdmin} from '../../util/requestUtil';
import dayjs from 'dayjs';


export const EditChangDi = props => {
    const {info} = props;
    const [form] = Form.useForm();
    const [isEdit, setEdit] = useState(false);
    const [timeList, setTimeList] = useState([]);
    const [changdiList, setChangdiList] = useState([]);
    const [changdi, setChangdi] = useState();
    const [showDelModel, setShowDelModel] = useState(false);
    const [deleteData, setDeleteData] = useState(false);
    const [adminOptions, setAdminOptions] = useState([]);

    const deleteOk = async () => {
        console.log('del', deleteData)
        const res = await deleteChangDiInfo(deleteData.id);
        if (res?.error === 0) {
            message.success('删除成功');
            loadChangDiData(info);
        } else {
            message.error('删除失败');
        }
        setDeleteData(undefined);
        setShowDelModel(false);
    };

    const deleteCancel = () => {
        setDeleteData(undefined);
        setShowDelModel(false);
    };

    const resetEdit = () => {
        setChangdi(undefined);
        setTimeList([]);
    };
    const addChangdi = () => {
        clearForm();
        setEdit(true);
    };
    const cancelEdit = () => {
        resetEdit();
        setEdit(false);
    };

    const loadChangDiData = async (info) => {
        const res = await loadChangDiList(info.id);
        setChangdiList(res?.data?.list || []);
    };

    const onFinish = async (values) => {
        values.status = values.status ? 0: 1;
        values.data = timeList.filter(item => !item.temp);
        values.vid = info.id;
        if (changdi) {
            values.id = changdi.id;
        }

        const res = await saveChangDiInfo(values);
        if (res.error === 0) {
            message.success(values.id ? '修改成功': '添加成功');
            resetEdit();
            setEdit(false);
            loadChangDiData(info);
        } else {
            message.error('参数错误');
        }
    };
    const onFinishFailed = () => {};

    const addYuYueDate = () => {
        setTimeList([...timeList, {temp: true}])
    };

    const dateChange = (date, itemData) => {
        const time = date.toDate().getTime();
        delete itemData.temp;
        itemData.dayTime = time;
        setTimeList([...timeList]);
    };

    const deleteTime = (itemData, index) => {
        // const list = timeList.filter(item => item !== itemData);
        timeList.splice(index, 1);
        setTimeList([...timeList]);
    }

    const copyTimeItem = (itemData, index) => {
        console.log('copy---', itemData, index)
        timeList.splice(index, 0, JSON.parse(JSON.stringify(itemData)));
        setTimeList([...timeList]);
    };

    const timeChange = (time, index, itemData) => {
        itemData.minuteList = itemData.minuteList || [];
        const minuteList = itemData.minuteList;
        const hasindex = minuteList.findIndex(item => item?.start === time);
        if (hasindex !== -1) {
            minuteList.splice(hasindex, 1);
        } else {
            minuteList.push({start: time, end: time + 30});
        }
        setTimeList([...timeList]);
    };

    const renderDate = (data, index) => {
        const start = 7 * 60;
        const end = 20 * 60;
        const timeSelectList = [];
        for (let i = start; i < end; i += 30) {
            timeSelectList.push(i);
        }

        let defaultValue;
        if (data.dayTime) {
            defaultValue = dayjs(new Date(data.dayTime));
        }
        const minuteList = data.minuteList || [];

        return <div key={'k' + data.dayTime + index} className='time-list-item list-item'>
            <Space>
                <DatePicker defaultValue={defaultValue} onChange={(value) => dateChange(value, data)} />
                <Button onClick={() => deleteTime(data, index)}>删除</Button>
                <Button onClick={() => copyTimeItem(data, index)}>复制</Button>
            </Space>
            <div>
                {timeSelectList.map((item, index) => {
                    const hour = Math.floor(item / 60);
                    let min = `${item % 60}`;
                    min = min.length === 1 ? `${min}0` : min;
                    const dateStr = `${hour}:${min}`;
                    const isSel = minuteList.find(d => d?.start === item);
                    return <div
                        onClick={() => timeChange(item, index, data)}
                        className={`time-item ${isSel ? 'time-item-select' : ''}`}
                        key={'time' + item}>{dateStr}</div>
                })}
            </div>
        </div>;
    };

    const deleteChangDi = (itemData) => {
        setDeleteData(itemData);
        setShowDelModel(true);
    };

    const clearForm = () => {
        setChangdi(undefined);
        setTimeList([]);
        form.setFieldsValue({
            name:'',
            machine_name: '',
            venue_admin: [],
            status: true,
            timeList: [],
        });
    };

    const modifyChangDi = (itemData) => {
        clearForm();
        try {
            const list = JSON.parse(itemData.data) || [];
            setTimeList(list);
        } catch (e) {
            setTimeList([]);
        }
        let venueAdmin = [];
        if (itemData.venue_admin) {
            try {
                const json = JSON.parse(itemData.venue_admin);
                venueAdmin = json;
            } catch (e) {
            }
        }
        setChangdi(itemData);
        setEdit(true);
        form.setFieldsValue({
            ...itemData,
            status: itemData.status === 0,
            venue_admin: venueAdmin,
        });
    };

    const loadChangGuanAdmin = async () => {
        const {data} = await getChangDiAdmin();
        console.log(data)
        let options = [];
        data?.forEach?.(item => {
            const {id, name, phone} = item;
            options.push({
                value: id,
                label: `${name}(${phone})`,
            });
        });
        setAdminOptions(options);
    };

    useEffect(() => {
        loadChangGuanAdmin();
        loadChangDiData(info);
    }, [info]);

    const columns = [
        {title: '场地名', dataIndex: 'name',},
        {title: '机器名', dataIndex: 'machine_name',},
        {
            title: '预约状态', dataIndex: 'status',
            render: (status) => <>{status === 0 ? '可预约' : '不可预约'}</>,
        },
        {
            title: '操作',
            render: (v, data) => <Space>
                <Button onClick={() => modifyChangDi(data)} type="primary">修改</Button>
                <Button onClick={() => deleteChangDi(data)}>删除</Button>
            </Space>
        }
    ];
    const rowSelection = {};

    return <><div>
        <div className='changguan-title'>{info?.name || ''}</div>
        <div>
            <Table rowKey={record=> 'row' + record.id} rowSelection={rowSelection} dataSource={changdiList} columns={columns} />
        </div>
        <div><Button type="primary" onClick={addChangdi}>添加场地</Button></div>
        {isEdit && <Form
            form={form}
            name="changdi"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={{status: true}}
        >
            <Form.Item
                label="场地名"
                name="name"
                rules={[{ required: true, message: '请输入场地名!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="机器名"
                name="machine_name"
                rules={[{ required: true, message: '请输入机器名!' }]}
                >
                <Input />
            </Form.Item>

            <Form.Item
                label="场地管理员"
                name="venue_admin"
            >
                <Select
                    mode="multiple"
                    allowClear
                    options={adminOptions}
                />
            </Form.Item>

            <Form.Item
                label="可否预约"
                name="status"
                >
                <Switch checkedChildren="预约" unCheckedChildren="停止" />
            </Form.Item>

            <Row>
                <Col span={8} style={{textAlign: 'right'}}>预约时间设置: </Col>
                <Col span={16}>
                    {timeList.map((item, index) => {
                        return renderDate(item, index);
                    })}
                </Col>
            </Row>
            <Row>
                <Col span={8}></Col>
                <Col span={16}>
                    <Button type="primary" onClick={addYuYueDate}>添加预约日期</Button>
                </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Space style={{paddingTop: '20px'}}>
                    <Button type="primary" htmlType="submit">{changdi ? '修改' : '保存'}</Button>
                    <Button onClick={cancelEdit}>取消</Button>
                </Space>
            </Form.Item>
        </Form>}
    </div>
    <Modal title="删除确认" open={showDelModel} onOk={deleteOk} onCancel={deleteCancel}>
        <p>确认删除场地：{deleteData?.name}</p>
    </Modal>
    </>
};