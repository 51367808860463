import './index.css';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Table,Switch, Form, Input, Select, Tabs, Space, InputNumber, Slider, Row, Col, Modal, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {getCommand, saveCommand, getConfig} from '../../util/requestUtil';
import {XiaoJieForm} from '../xiaojie-form';


export const XiaoJie = props => {
	const [showForm, setShowForm] = useState(false);
	const [commandData, setCommandData] = useState({});
	const [showAddUnit, setShowAddUnit] = useState(false);
	const [formData, setFormData] = useState();
	const [editUnitData, setEditUnitData] = useState({});
	const [showFormIndex, setShowFormIndex] = useState(-1);


	// NTRP评分，运动概况，底线-落点，底线-力量，底线-姿态，底线-切削，底线-小球，发球-落点，发球-力量，发球-姿态，网前-截击，移动能力，
	// NTRP评分，运动时长，平均球速，移动距离，击球次数，消耗热量，方向控制，第一落点，深度，球速，动力链，第二落点，
	// 挥拍完整度，击球点，第三落点，优质球，移动能力，站位， 正反手，发球


	const loadCommandData = async () => {
		const result = await getCommand();
		let commandData = result?.data || {data: []};
		if (typeof commandData.data === 'string') {
			try {
				commandData.data = JSON.parse(commandData.data);
			} catch (e) {
				commandData.data = [];
			}
		}
		setCommandData(commandData);
		// setShowForm(false);
		setShowFormIndex(-1);
	};

	// 表单保存
	const onResult = async (values) => {
		const {itemIndex, listIndex} = values;
		delete values.itemIndex;
		delete values.listIndex;

		const newData = {
			...commandData,
		};
		newData.data = newData.data || [];
		commandData.data[listIndex].list = commandData.data[listIndex].list || [];
		const itemList = commandData.data[listIndex].list;
		if (itemList[itemIndex]) {
			itemList[itemIndex] = values;
		} else {
			itemList.push(values);
		}

		const res = await saveCommand(newData);
		loadCommandData();

		// setShowForm(false);
		setShowFormIndex(-1);
		setFormData(null);
	};

	const cancelEdit = () => {
		// setShowForm(false);
		setShowFormIndex(-1);
	};

	useEffect(() => {
		loadCommandData();
	}, []);


	const removeItem = async (item, index, listIndex) => {
		Modal.confirm({
			title: '删除确认',
			content: `确认删除 ${item.name}`,
			onOk: async () => {
				const list = commandData.data[listIndex].list;
				list.splice(index, 1);
				const newData = {...commandData};
				const res = await saveCommand(newData);
				loadCommandData();
				message.success('删除成功');
			},
			okText: '删除',
			cancelText: '取消'
		});
	};

	const modifyItem = async (item, itemIndex, listIndex) => {
		setShowFormIndex(listIndex);
		// setShowForm(true);
		setFormData({
			...item,
			itemIndex,
			listIndex
		});
	};


	// 添加/修改章节
	const modifyUnit = (listItem, listIndex) => {
		setEditUnitData({
			index: listIndex,
			name: listItem?.name || '',
		});
		setShowAddUnit(true);
	};

	const removeUnit = (listItem, listIndex) => {
		const unit = commandData.data[listIndex];
		Modal.confirm({
			title: '删除确认',
			content: `确认删除 ${unit.name}`,
			onOk: async () => {
				commandData.data.splice(listIndex, 1);
				console.log('0---', commandData)
				const newData = {...commandData};
				const res = await saveCommand(newData);
				loadCommandData();
				message.success('删除成功');
			},
			okText: '删除',
			cancelText: '取消'
		});
	};

	const clearEditUnit = () => {
		setShowAddUnit(false);
	};

	// 添加/修改单元测试
	const addEditUnit = async () => {
		const {index = -1, name} = editUnitData;

		const newData = {
			...commandData,
		};
		newData.data = newData.data || [];
		if (newData?.data?.[index]) {
			newData.data[index].name = name;
		} else {
			newData.data.push({
				name,
				list: [],
			});
		}

		// 
		const res = await saveCommand(newData);
		loadCommandData();

		setEditUnitData({});
		setShowAddUnit(false);
	};

	// 单页测试名修改
	const unitInputChange = (event) => {
		setEditUnitData({
			...editUnitData,
			name: event.target.value
		});
	};



	const commandList = commandData?.data;

	return <div>
		{commandList?.map((item, listIndex) => {
			const columns = [
				{
					title: '名称',
					dataIndex: 'name',
				},
				{
					title: '测试内容',
					dataIndex: 'test_content',
				},
				{
					title: '提示',
					dataIndex: 'message',
				},
				{
					title: '操作',
					dataIndex: 'name',
					render: (name, item, index) => {
						return <>
							<Button onClick={() => modifyItem(item, index, listIndex)}>修改</Button>
							<Button onClick={() => removeItem(item, index, listIndex)}>删除</Button>
						</>
					},
				},
			];
			const dataSource = item.list;
			return <div className='unit-item'>
				<Space>
					<div>测试章节: {item.name}</div>
					<Button onClick={() => modifyUnit(item, listIndex)}>修改</Button>
					<Button onClick={() => removeUnit(item, listIndex)}>删除</Button>
				</Space>
				{!!dataSource?.length && <Table pagination={false} rowKey='id' columns={columns} dataSource={dataSource}></Table>}
				<div className='unit-form-button'><Button onClick={() => modifyItem(null, null, listIndex)}>添加测试内容</Button></div>
				{showFormIndex === listIndex && <div className='unit-form'>
					<XiaoJieForm formData={formData} cancelEdit={cancelEdit} onResult={onResult} />
				</div>}
			</div>;
		})}
		
		<Button className='add-unit-btn' onClick={modifyUnit}>添加章节</Button>

		{showAddUnit && <div>
			<Row>
				<Col span={4}>章节名</Col>
				<Col span={20}><Input onChange={unitInputChange} value={editUnitData?.name || ''}/></Col>
			</Row>
			<Row>
				<Col span={4}></Col>
				<Col span={20}>
					<Button type="primary" onClick={addEditUnit}>{editUnitData.index !== undefined ? '修改' : '保存'}</Button>
					<Button onClick={clearEditUnit}>取消</Button>
				</Col>
			</Row>
		</div>}

		{showForm && <div className='form-layer'>
			<div className='form-content'>
				<XiaoJieForm formData={formData} cancelEdit={cancelEdit} onResult={onResult} />
			</div>
		</div>}
	</div>
};